:root {
  --anim-tag: 40%;
}

.main {
  background: linear-gradient(180deg, #f0f4ff 20%, 30%, #ffffff 50%);
}

.wrapper {
  width: min(100%, 1440px);
  margin: auto;
  padding: 4em 4em;
}

@media (max-width: 970px) {
  .wrapper {
    padding: 0;
    padding-block: 1.5em;
    padding-inline: 1.5rem;
  }
}

.section_hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 60px;
}

.hero_info {
  flex: 1;
  margin-right: 3em;
}

.hero_text {
  max-width: 23ch;
}

.hero_desc {
  margin-top: 12px;
  margin-bottom: 32px;
  line-height: 1.45;
  max-width: 55ch;
}

.tag_line_container ul {
  position: relative;
  overflow: hidden;
}

.tag_line_container li {
  position: absolute;
}

.button {
  color: var(--color-primary);
  border: none;
  background: #f0f4ff;
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  padding: 18px 30px;
}

.buttons_wrapper {
  display: none;
}

.qr_code {
  display: flex;
  align-items: center;
  gap: 16px;
}

.qr_code p {
  color: #101d3c;
  max-width: 12ch;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.1;
}

@media (max-width: 768px) {
  .buttons_wrapper {
    display: flex;
  }
  .qr_code {
    display: none;
  }
}

@media (max-width: 995px) {
  .section_hero {
    margin-top: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .hero_info {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0;
    margin-bottom: 50px;
  }
}

.desc_section_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 60px;
}

@media (max-width: 1310px) {
  .desc_section_wrapper {
    justify-content: space-evenly;
  }
}

.desc_section {
  width: min(100%, 373px);
  height: min(100%, 204px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.icon_overlay {
  width: 60px;
  height: 60px;
  background: var(--color-primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
}

.description {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #f0f4ff;
  margin-top: 30px;
  padding: 48px 32px 32px 32px;
  border-radius: 24px;
  text-align: center;
}

.description p {
  margin-top: 24px;
  font-size: 1rem;
  line-height: 22.4px;
}

@media (max-width: 926px) {
  .desc_section:nth-child(2) {
    margin-bottom: 64px;
    margin-top: 64px;
  }

  footer {
    flex-direction: column-reverse;
    align-items: center;
  }

  footer p {
    width: fit-content;
    margin-bottom: 32px;
  }
}

footer {
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin-top: 145px;
  margin-bottom: 32px;
}

@media (max-width: 413px) {
  .hero_desc {
    font-size: calc(0.8rem + 0.5vw);
    margin-top: 12px;
    width: 100%;
  }

  .hero_text {
    width: 100%;
  }
}
