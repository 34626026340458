@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap');

body,
h2,
h4,
p,
li,
ul,
ol {
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img,
picture {
  max-width: 100%;
  display: block;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --color-primary: #1f54d3;
  --white: #ffffff;
  --gray1: #333333;
  --gray3: #828282;
}

html:focus-within {
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Rubik', sans-serif;
  text-rendering: optimizeSpeed;
  font-size: 100%;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
  overflow-x: hidden;
}

.showcase_image {
  align-self: flex-end;
  width: 370px;
}

/* @media (max-width: 970px) {
  body {
    padding-inline: 1.5rem;
  }
  img,
  picture {
    max-width: 70%;
    display: block;
  }
} */

h2 {
  font-weight: 600;
  font-size: clamp(2rem, 1.8rem + 1vw, 2.8rem);
  color: #101d3c;
  line-height: 1.3;
}

.schoolarLogo-md {
  display: none;
}

h3 {
  font-weight: 600;
  font-size: clamp(2rem, 1rem + 1.5vw, 2.25rem);
  line-height: 1.3;
}

h4 {
  font-weight: 600;
  font-size: clamp(1rem, 1rem + 1.5vw, 1.5rem);
}

p {
  font-weight: 400;
  font-size: clamp(0.8rem, 1.2rem + 0.8vw, 1.1rem);
  color: #334269;
}

@media (max-width: 425px) {
  h2 {
    font-size: clamp(1.14rem, 1rem + 0.6vw, 1.8rem);
  }

  .schoolarLogo {
    display: none;
  }

  .schoolarLogo-md {
    display: flex;
  }

  p {
    font-size: clamp(0.9rem, 0.8rem + 0.6vw, 1.12rem);
  }
}

@media (max-width: 320px) {
  h2 {
    font-size: clamp(1rem, 0.8rem + 0.4vw, 1.4rem);
  }

  .schoolarLogo {
    display: none;
  }

  .schoolarLogo-md {
    display: flex;
  }
}
